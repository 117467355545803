import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0c5dee230246ec38e51f1858cf43f7eb@o4508213517615104.ingest.de.sentry.io/4508213519515728",
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
